import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CardActions, CardHeader, Divider, colors, Button
} from '@material-ui/core';

import { ParamsPagination, usePagination, useFiltersSearch } from 'utils';
import { CheckoutHistoryFilter, Label } from 'components';
import { useFormContext } from 'react-hook-form';
import ClearIcon from '@material-ui/icons/Clear';
import { Search } from 'components/SearchBar/components';
import { CHECKOUT_HISTORY_SERVICES } from 'common';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  rowTable: {
    verticalAlign: 'top'
  },
  tableWrapper: {
    height: 'calc(100vh - 240px)',
    overflow: 'auto',
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
  linkText: {
    color: colors.blue[900],
    '&:hover': {
      color: colors.blue[800],
    },
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '0.7rem',
    minWidth: 150,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    textAlign: 'left',
  },
  cartId: {
    textAlign: 'left',
    minWidth: 150
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  headActions: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:701px)']: {
      marginTop: theme.spacing(2),
    }
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
}));


const ResultRow = props => {
  const { item, initRequest } = props;

  const classes = useStyles();

  const renderEventLabel = (event, state) => {
    switch (event) {
      case 'Order Creation':
        return <Label color={colors[state === 'success' ? 'lightBlue' : 'red'][600]} variant={'contained'}>{event}</Label>
      case 'Pre Approval':
        return <Label color={colors[state === 'success' ? 'green' : 'red'][300]} variant={'contained'}>{event}</Label>
      default:
        return null
    }
  };

  return (
    <TableRow
      key={item._id}
      className={classes.rowTable}
    >
      <TableCell
        className={classes.whiteSpace}
      >
        {moment(item.createdAt).format(
          'DD MMM YYYY   h:mm a'
        )}
      </TableCell>

      <TableCell  >
        {item.type_service}
      </TableCell>

      <TableCell >
        <Button
          className={classes.linkText}
          color="primary"
          onClick={() => {
            initRequest(item.cart_id);
          } }
          size="small"
        >
          {item.cart_id}
        </Button>
      </TableCell>

      <TableCell >
        {item.state === 'success' ? <Label
          color={colors.green[600]}
          variant="outlined"
        >Success</Label> : <Label color={colors.red[600]} variant="outlined">Not success</Label>}
      </TableCell>
      <TableCell>{renderEventLabel(item.event, item.state)}</TableCell>

      <TableCell  >
        {item.data}
      </TableCell>

    </TableRow>
  )
};


const CheckoutHistoryResults = props => {
  const { className, list, count, isProgressInHistory, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const {page, limit} = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { fromDate, toDate, status, state, setFromDate, setToDate, setStatus, setState, queryText, setQueryText} = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, status, state]);

  const { setValue } = useFormContext();

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearStatus = useCallback(() => {
    setStatus('')
    setValue('service', '', true);
    dataReloadInit();
  }, [dataReloadInit, setStatus, setValue]);

  const clearState = useCallback(() => {
    setState('')
    setValue('state', '', true);
    dataReloadInit();
  }, [dataReloadInit, setState, setValue]);

  const clearSearch = useCallback(() => {
    setQueryText('');
    dataReloadInit();
  }, [dataReloadInit, setQueryText]);

  const initRequest = useCallback(async (cardID) => {
    setValue('search', cardID || '', true);
    await setQueryText(cardID);
  }, [setQueryText, setValue]);

  const getNameService = useCallback((service) => {
    return CHECKOUT_HISTORY_SERVICES.find((elem) => elem.value === service)?.label;
  }, []);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        { queryText && <Button name='btn-search' onClick={clearSearch} className={classes.filterLabel} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {`Search: [${queryText}] `} </Button>}
        { fromDate && <Button
          onClick={clearFromDate}
          name = {'btn-from-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `}
        </Button> }
        { toDate && <Button
          onClick={clearToDate}
          name = {'btn-to-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`}
        </Button> }
        { status && <Button
          onClick={clearStatus}
          name = {'btn-status'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Service: [${getNameService(status)}]`}
        </Button> }
        { state && <Button
          onClick={clearState }
          name = {'btn-state'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` State: [${state}]`}
        </Button> }
      </div>

      <Card
        {...rest}
        className={clsx(classes.root, className)}>
        <CardHeader
          className={classes.cardHeader}
          ref = { cardHeaderRef }
          title={
            <div className={classes.container}>
              <Search dataReloadInit={dataReloadInit} />
            </div>
          }
          action={
            <CheckoutHistoryFilter
              className={classes.headActions}

              dataReloadInit={dataReloadInit}
            />
          }/>


        <Divider />
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            { list.length>0 && !isProgressInHistory && <Table
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell >
                    Date
                  </TableCell>
                  <TableCell>
                    Service
                  </TableCell>
                  <TableCell
                    className={classes.cartId}
                  >
                    Cart ID
                  </TableCell>

                  <TableCell>
                    State
                  </TableCell>

                  <TableCell >
                    Event
                  </TableCell>

                  <TableCell >
                    Data
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {list?.map((item, i) => (
                  <ResultRow
                    item={item}
                    initRequest={initRequest}
                    key={i}
                  />
                ))}
              </TableBody>
            </Table> }
            { isProgressInHistory && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div> }
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {count} History found
            {count > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(count / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressInHistory}
            recordsCount={count}
          />
        </CardActions>
      </Card>
    </div>
  );
};

//{smsMessagesCount} Charges found ( from {moment(fromDate).format('DD MMM YYYY')} to {moment(toDate).format('DD MMM YYYY')} )
CheckoutHistoryResults.propTypes = {
  className: PropTypes.string,
  isProgressInHistory: PropTypes.bool.isRequired,
  list: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired
};

CheckoutHistoryResults.defaultProps = {
  list: [],
  count: 0,
  isProgressInHistory: false
};

export default CheckoutHistoryResults;
