import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Button, Card, CardActions, CardContent, CardHeader, colors, Divider, Grid, Typography} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios_v2 from 'utils/axios_v2';
import notifyError from 'utils/common';
import {toast} from 'react-toastify';
import {DropzoneArea} from 'material-ui-dropzone';
import Alert from '@material-ui/lab/Alert';
import _ from 'lodash';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Box from '@material-ui/core/Box';
import {lighten, withStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';


const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten('#b9bdb9', 0.5),
    marginBottom: '0px!important'
  },
  bar: {
    backgroundColor: '#158a17'
  }
})(LinearProgress);

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
  },
  rootCart: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  message: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  description: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-wrap'
  },
  importFileInfo: {
    display: 'flex',
    gap: '5px',
    margin: '5px 0'
  },
  progressMessages: {
    marginTop: '40px'
  },
  importFileInfoLabel: {
    width: '80px',
    display: 'inline-block',
  },
  importFileInfoWithPadding: {
    paddingLeft: '30px'
  }
}));

const ProductFileImport = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const [dropzoneKey, setDropzoneKey] = useState(0);
  const [uploadButtonEnabled, setUploadButtonEnabled] = useState(false);
  const [clearButtonEnabled, setClearButtonEnabled] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [infoMessage, setInfoMessage] = useState('');
  const [importProcessData, setImportProcessData] = useState({is_active: false});
  const [progressMessage, setProgressMessage ] = useState([]);
  const [percentValue, setPercentValue] = useState('0');
  const [isNeedDisplayLastImportInfo, setIsNeedDisplayLastImportInfo] = useState(localStorage.getItem('isNeedDisplayLastImportInfo') === 'true');


  const handleButtonClear = useCallback( () => {
    setFiles([]);
    setWarnings([]);
    setInfoMessage('');
    setProgressMessage([]);
    setPercentValue('0');
    setImportProcessData({});

    localStorage.removeItem('isNeedDisplayNotification');
    localStorage.removeItem('isNeedDisplayLastImportInfo');
    if (isNeedDisplayLastImportInfo) setIsNeedDisplayLastImportInfo(false);
  }, [isNeedDisplayLastImportInfo]);

  const handleChooseFile = useCallback( (files) => {
    if (!importProcessData.is_active) {
      setFiles(files || []);

      setWarnings([]);
      setInfoMessage('');
      setProgressMessage([]);
      setPercentValue('0');
      setImportProcessData({});

      localStorage.removeItem('isNeedDisplayNotification');
      localStorage.removeItem('isNeedDisplayLastImportInfo');
      if (isNeedDisplayLastImportInfo) setIsNeedDisplayLastImportInfo(false);
    }
  }, [importProcessData, isNeedDisplayLastImportInfo]);

  const getStatusRequest = useCallback(() => {
    return axios_v2().get('/products_sync_status')
      .then(response => {
        if (response.status === 200 && response?.data?.last) {
          response.data.last.is_active = typeof response?.data?.last?.is_active !== 'undefined' ? response.data.last.is_active : false;

          const isNotif = localStorage.getItem('isNeedDisplayNotification') === 'true';

          // If finished process - display notification
          if (isNotif && !response.data.last.is_active) {
            response.data.last.status === 'success' && toast.success('Import of the GIGA products file has been successfully completed');
            response.data.last.status === 'fail' && notifyError('Import of the GIGA products file has been finished with errors');
            localStorage.removeItem('isNeedDisplayNotification');
          }

          let isCurrentInfo = response.data.last.is_active
            || isNeedDisplayLastImportInfo
            || isNotif;
          let response_data = isCurrentInfo
            ? response.data.last
            : (response.data.last_success);

          // console.log('[11.3] isCurrentInfo=' + isCurrentInfo + ' response_data=', response_data); // DEBUG

          setImportProcessData(response_data);
          if (!!response_data.progress) setPercentValue(response_data.progress);

          const newMessage = response_data?.message;
          let status = response_data?.status;

          setProgressMessage(prevProgress => {
            const lastMessage = prevProgress[prevProgress.length - 1]?.message;

            if (lastMessage !== newMessage) {
              return [...prevProgress, {message: newMessage, status: status }];
            }
            return prevProgress;
          });

        }
      }).catch(e => console.error(e));

  },[isNeedDisplayLastImportInfo]);


  const handleButtonUploadFile = useCallback( () => {
    let formData = new FormData();
    formData.append('filedata', files[0]);
    const file_name = files[0].name;

    setWarnings([]);
    setInfoMessage('');
    setProgressMessage([]);
    setPercentValue('0');

    axios_v2(true).post('/import_giga_file', formData).then((res) => {
      // console.log(res);  // DEBUG
      let successText = '';

      if (res?.request.status === 200) {
        /* Format response: res?.data = {
              is_active: true,
              status: 'pending',
              message: 'Start import GIGA file',
              file_name: file_name,
              file_size: file_size,
          }
         */
        if (res?.data?.is_active && res?.data?.status === 'pending') {
          localStorage.setItem('isNeedDisplayNotification', 'true');
          localStorage.setItem('isNeedDisplayLastImportInfo', 'true');
          setIsNeedDisplayLastImportInfo(true);
        }

        successText = res?.data?.message;
        setImportProcessData(res?.data);
        setProgressMessage(prevProgress => [...prevProgress, {message: res?.data?.message, status: res?.data?.status } ]);
        if (!!res.data.progress) setPercentValue(res.data.progress);
      } else {
        successText = 'File Upload Finished With Warnings';
        if (res.data.message) setWarnings(res.data.message);
      }

      if (res.data && _.isNumber(res.data.rows_count)) {
        setInfoMessage(res.data.rows_count + ' rows from file ' + file_name + ' imported successfully');
      }

      toast.success(successText);

      setFiles([]);
      setDropzoneKey(dropzoneKey+1);

    }).catch(response => {
      console.error('Catch handleButtonUploadFile ...');
      console.error(response?.response?.data?.message);
      notifyError(( response?.response?.data?.message ? response.response.data.message : 'Server communication error'));
      setFiles([]);
      getStatusRequest();
    });
  }, [files, dropzoneKey, getStatusRequest]);


  useEffect(() => {
    getStatusRequest();
  }, [getStatusRequest]);


  useEffect( () => {
    let idTimer = null;

    if(importProcessData.is_active) {
      idTimer = setInterval(getStatusRequest, 5000);
    }

    return () => {
      if (idTimer) clearInterval(idTimer);
    };
  }, [importProcessData, getStatusRequest]);


  useEffect(() => {
    const isEnabledButton = files && files.length && !importProcessData.is_active;
    if (isEnabledButton) {
      setWarnings([]);
      setInfoMessage('');
      setProgressMessage([]);
      setPercentValue('0');

      localStorage.removeItem('isNeedDisplayNotification');
      localStorage.removeItem('isNeedDisplayLastImportInfo');
      setIsNeedDisplayLastImportInfo(false);
    }
    setUploadButtonEnabled(!!isEnabledButton);
  }, [files, importProcessData]);


  useEffect(() => {
    setClearButtonEnabled(!uploadButtonEnabled && !!isNeedDisplayLastImportInfo && !importProcessData.is_active);
  }, [uploadButtonEnabled, isNeedDisplayLastImportInfo, importProcessData.is_active]);


  let startedDate, finishedDate, fileSizeKb, fileName, owner_name, owner_email;

  if (importProcessData.started) {
    startedDate = new Date(importProcessData.started);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    startedDate = startedDate.toLocaleDateString('en-US', options);
  }

  if (importProcessData.finished) {
    finishedDate = new Date(importProcessData.finished);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    finishedDate = finishedDate.toLocaleDateString('en-US', options);
  }

  if (importProcessData.file_size) {
    fileSizeKb = (importProcessData.file_size / 1024).toFixed(3);
  }

  if (importProcessData?.file_name) {
    fileName = importProcessData?.file_name;
  }

  if (!!importProcessData?.owner_name) {
    owner_name = importProcessData?.owner_name;
  }

  if (!!importProcessData?.owner_email) {
    owner_email = importProcessData?.owner_email;
  }

  let infoLabel = '';
  if((importProcessData.is_active || isNeedDisplayLastImportInfo)
    && !files?.length) {
    infoLabel = 'Current Import File Info';
  } else if(importProcessData.status === 'success') {
    infoLabel = 'Last Success Import File Info';
  } else {
    infoLabel = 'Last Import File Info';
  }

  return (
    <React.Fragment>
      <Card
        {...rest}
        className={clsx(classes.rootCart, className)}
      >
        <CardHeader
          title={importProcessData.is_active
            ? 'The process of importing Giga products from a file is in progress. Please wait until it is completed ...'
            : 'Select file for import. Push \'Upload file\' button.'}
        />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <DropzoneArea
                acceptedFiles={['.xlsx', '.xls']}
                dropzoneClass={'dropzoneCustomize'}
                dropzoneText={importProcessData.is_active
                  ? 'Do not add a file, please wait until the import file is completed ...'
                  : 'Drag and drop a file here or click here for select'}
                filesLimit={1}
                key={dropzoneKey}
                maxFileSize={20000000}
                onChange={handleChooseFile}
                useChipsForPreview
              />
              {(importProcessData.is_active
                || isNeedDisplayLastImportInfo)
                && !files?.length  && <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '24px'}}>
                <Box sx={{width: '100%', mr: 1 }}>
                  <BorderLinearProgress
                    className={classes.root}
                    color="secondary"
                    value={parseInt(percentValue)}
                    variant="determinate"
                  />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography
                    sx={{ color: 'success.main' }}
                    variant="body2"
                  >{`${percentValue}%`}</Typography>
                </Box>
              </Box>}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <div>
                <b>{infoLabel}</b>
                {typeof fileName !== 'undefined' && <div className={classes.importFileInfo}>
                  <p>
                    <span className={classes.importFileInfoLabel}>Name:</span>
                    <span>{fileName}</span>
                  </p>
                </div>}
                {typeof fileSizeKb !== 'undefined' && <div className={classes.importFileInfo}>
                  <p>
                    <span className={classes.importFileInfoLabel}>Size:</span>
                    <span>{fileSizeKb} KB</span>
                  </p>
                </div>}
                {typeof owner_name !== 'undefined' && <div className={classes.importFileInfo}>
                  <p>
                    <span className={classes.importFileInfoLabel}>Import by:</span>
                    <span>{owner_name}, {owner_email}</span>
                  </p>
                </div>}
                {typeof startedDate !== 'undefined' && <div className={classes.importFileInfo}>
                  <p>Import started on {startedDate}</p>
                </div>}
                {typeof finishedDate !== 'undefined' && <div className={classes.importFileInfo}>
                  <p>Import finished on {finishedDate}</p>
                </div>}
                {(importProcessData.is_active
                  || isNeedDisplayLastImportInfo) && !files?.length && progressMessage.length > 0 && (
                  <div className={classes.progressMessages}>
                    <b>Import progress...</b>
                    {progressMessage.map((item, i) => (
                      <div className={classes.importFileInfo} key={'import-process-message-' + i}>
                        {
                          progressMessage.length - 1 === i && importProcessData.is_active ? (
                            <CircularProgress size={24}/>
                            ) : item.status === 'fail' ? (
                              <HighlightOffIcon
                                className={classes.ico}
                                style={{ color: colors.red[600] }}
                              />
                          ) : <CheckCircleIcon style={{color: colors.green[600]}}/>
                        }
                        <p>{item.message}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <Divider/>
        <CardActions>
          <Button
            color="primary"
            disabled={!uploadButtonEnabled}
            onClick={handleButtonUploadFile}
            variant="contained"
          >
            Upload file
          </Button>
          {clearButtonEnabled && <Button
            color="primary"
            disabled={!clearButtonEnabled}
            onClick={handleButtonClear}
            variant="contained"
          >
            Clear
          </Button>}
        </CardActions>
        {infoMessage && <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <Alert
              className={classes.message}
              severity="success"
              variant="outlined"
            >
              <div className={classes.description}>
                <Typography
                  color="textSecondary"
                >
                  {infoMessage}
                </Typography>
              </div>
            </Alert>
          </Grid>
        </Grid>}
        {warnings.length>0 && <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <Alert
              className={classes.message}
              severity="warning"
              variant="outlined"
            >
              <div className={classes.description}>
                <Typography
                  color="textSecondary"
                >
                  {warnings.join('\r\n')}
                </Typography>
              </div>
            </Alert>
          </Grid>
        </Grid>}
      </Card>
    </React.Fragment>
  );
};

ProductFileImport.propTypes = {
  className: PropTypes.string,
  supplier: PropTypes.string
};

export default ProductFileImport;
