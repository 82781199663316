import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, colors, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { toast } from 'react-toastify';
import axios from 'utils/axios.js';
import { getToastHttpError } from 'utils/common';
import { LEASE_PROVIDERS_TYPE } from 'common/Constants';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  action_label: {
    width: 200
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  noBorder: {
    borderColor: 'transparent'
  },
  padding_top_0: {
    paddingTop: '0'
  },
  updateButton: {
    color: theme.palette.white,
    backgroundColor:  colors.blue[600],
    '&:hover': {
      backgroundColor: colors.blue[900]
    }
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 2
  }
}));

const UpdateAttributes = props => {
  const { /*className,*/ order_id, lease_provider, lease_id, lease_number, checkout_token, application_id, ext_id, ext_id_history, transaction_id, store_type /*, ...rest*/ } = props;

  const classes = useStyles();
  const history = useHistory();

  const [isProgressIn, setIsProgressIn] = useState(false);

  const [leaseProviderNew, setLeaseProviderNew] = useState('');

  const handleLeaseProviderChange = value => {
    setLeaseProviderNew(value);
  };

  const [leaseIdNew, setLeaseIdNew] = useState('');

  const handleLeaseIdChange = event => {
    setLeaseIdNew(event.target.value);
  };

  const [leaseNumberNew, setLeaseNumberNew] = useState('');

  const handleLeaseNumberChange = event => {
    setLeaseNumberNew(event.target.value);
  };

  const [checkoutTokenNew, setCheckoutTokenNew] = useState('');

  const handleCheckoutTokenChange = event => {
    setCheckoutTokenNew(event.target.value);
  };

  const [applicationIdNew, setApplicationIdNew] = useState('');

  const handleApplicationIdChange = event => {
    setApplicationIdNew(event.target.value);
  };

  const [bbOrderIdNew, setBbOrderIdNew] = useState('');

  const handleBbOrderIdChange = event => {
    setBbOrderIdNew(event.target.value);
  };

  const [note, setNote] = useState('');

  const handleNoteChange = event => {
    setNote(event.target.value);
  };

  const [transactionIdNew, setTransactionIdNew] = useState('');

  const handleTransactionIdChange = event => {
    setTransactionIdNew(event.target.value);
  };

  const updateOrder = () => {
    let data = {};

    data.mode = 'update';

    if (leaseProviderNew) data.lease_provider = leaseProviderNew;

    if (leaseIdNew) data.lease_id = leaseIdNew.trim();

    if (leaseNumberNew) data.lease_number = leaseNumberNew.trim();

    if (checkoutTokenNew) data.checkout_token = checkoutTokenNew.trim();

    if (applicationIdNew) data.application_id = applicationIdNew.trim();

    if (bbOrderIdNew) data.bb_order_id = bbOrderIdNew.trim();

    if (note) data.note = note.trim();

    if (transactionIdNew) data.transaction_id = transactionIdNew.trim();

    return axios().put(`orders/${order_id}/update`, data).then(() => {
      return `Order ID #${order_id} processed successfully`;
    }).catch(response => {
      getToastHttpError(response || 'Something Went Wrong');
      return Promise.reject('Request Failed');
    });
  };

  const handleButtonUpdate = () => {
    setIsProgressIn(true);
    setOpenUpdateConfirmation(false);
    updateOrder().then((success_message) => {
      setIsProgressIn(false);
      toast.success(success_message);
      history.push('/orders/' + order_id);
    });
  };

  const [openUpdateConfirmation, setOpenUpdateConfirmation] = useState(false);

  const handleClickOpenUpdateConfirmation = () => {
    setOpenUpdateConfirmation(true);
  };

  const handleCloseUpdateConfirmation = () => {
    setOpenUpdateConfirmation(false);
  };

  const [leaseProviderFields, setLeaseProviderFields] = useState([]);

  useEffect(  () => {
    let provider = leaseProviderNew || lease_provider;
    const itemLeaseProvider = LEASE_PROVIDERS_TYPE.find((elem) => elem.value === provider);
    if (itemLeaseProvider) {
      setLeaseProviderFields(itemLeaseProvider.fields || '');
    }
  }, [setLeaseProviderFields, leaseProviderNew, lease_provider]);

  return (<div >
    <Table>
      <TableBody>
      <TableRow>
        <TableCell className={classes.action_label}>
          <Typography variant="subtitle2">
            Current Lease Provider
          </Typography>
        </TableCell>
        <TableCell>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.content}>
                  <Typography
                    variant="h6"
                  >
                    {LEASE_PROVIDERS_TYPE.find((provider) => provider.value === lease_provider)?.label}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.action_label}>
          <Typography variant="subtitle2">
            New Lease Provider
          </Typography>
        </TableCell>
        <TableCell>
          <TextField
            className={classes.field}
            fullWidth
            label="Lease Provider"
            name="lease_provider"
            onChange={({currentTarget}) => handleLeaseProviderChange(currentTarget.value)}
            select
            // eslint-disable-next-line react/jsx-sort-props
            SelectProps={{ native: true }}
            value={leaseProviderNew}
            variant="outlined"
          >
            <option value="" />
            {LEASE_PROVIDERS_TYPE.map(option => ((!store_type || option.store_type === store_type) ?
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option> : false
            ))}
          </TextField>
        </TableCell>
      </TableRow>
      {leaseProviderFields?.includes('lease_id') && <><TableRow>
        <TableCell className={classes.action_label}>
          <Typography variant="subtitle2">
            Current Lease ID
          </Typography>
        </TableCell>
        <TableCell>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.content}>
                  <Typography
                    variant="h6"
                  >
                    {lease_id}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.action_label}>
          <Typography variant="subtitle2">
            New Lease ID
          </Typography>
        </TableCell>
        <TableCell>
          <TextField
            className={classes.field}
            fullWidth
            label="Lease ID"
            margin="dense"
            name="leaseId"
            onChange={handleLeaseIdChange}
            value={leaseIdNew}
            variant="outlined"
            autoComplete="off"
          />
        </TableCell>
      </TableRow></>}
      {leaseProviderFields?.includes('lease_number') && <><TableRow>
        <TableCell className={classes.action_label}>
          <Typography variant="subtitle2">
            Current Lease Number
          </Typography>
        </TableCell>
        <TableCell>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.content}>
                  <Typography
                    variant="h6"
                  >
                    {lease_number}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.action_label}>
          <Typography variant="subtitle2">
            New Lease Number
          </Typography>
        </TableCell>
        <TableCell>
          <TextField
            className={classes.field}
            fullWidth
            label="Lease Number"
            margin="dense"
            name="leaseNumber"
            onChange={handleLeaseNumberChange}
            value={leaseNumberNew}
            variant="outlined"
            autoComplete="off"
          />
        </TableCell>
      </TableRow></>}
      {leaseProviderFields?.includes('checkout_token') && <><TableRow >
        <TableCell className={classes.action_label}>
          <Typography variant="subtitle2">
            Current Checkout Token
          </Typography>
        </TableCell>
        <TableCell>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.content}>
                  <Typography
                    variant="h6"
                  >
                    {checkout_token}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.action_label}>
          <Typography variant="subtitle2">
            New Checkout Token
          </Typography>
        </TableCell>
        <TableCell>
          <TextField
            className={classes.field}
            fullWidth
            label="Checkout Token"
            margin="dense"
            name="checkoutToken"
            onChange={handleCheckoutTokenChange}
            value={checkoutTokenNew}
            variant="outlined"
            autoComplete="off"
          />
        </TableCell>
      </TableRow></>}
      {leaseProviderFields?.includes('application_id') && <><TableRow >
        <TableCell className={classes.action_label}>
          <Typography variant="subtitle2">
            Current Application ID
          </Typography>
        </TableCell>
        <TableCell>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.content}>
                  <Typography
                    variant="h6"
                  >
                    {application_id}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.action_label}>
          <Typography variant="subtitle2">
            New Application ID
          </Typography>
        </TableCell>
        <TableCell>
          <TextField
            className={classes.field}
            fullWidth
            label="Application ID"
            margin="dense"
            name="applicationId"
            onChange={handleApplicationIdChange}
            value={applicationIdNew}
            variant="outlined"
            autoComplete="off"
          />
        </TableCell>
      </TableRow></>}
      {leaseProviderFields?.includes('transaction_id') && <><TableRow >
          <TableCell className={classes.action_label}>
            <Typography variant="subtitle2">
              Current Transaction ID
            </Typography>
          </TableCell>
          <TableCell>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.content}>
                    <Typography
                      variant="h6"
                    >
                      {transaction_id}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.action_label}>
            <Typography variant="subtitle2">
              New Transaction ID
            </Typography>
          </TableCell>
          <TableCell>
            <TextField
              className={classes.field}
              fullWidth
              label="Transaction ID"
              margin="dense"
              name="transactionId"
              onChange={handleTransactionIdChange}
              value={transactionIdNew}
              variant="outlined"
              autoComplete="off"
            />
          </TableCell>
        </TableRow></>}
        { ext_id_history && ext_id_history.length>0 && <TableRow>
          <TableCell className={classes.action_label}>
            <Typography variant="subtitle2">
              Current BestBuy Order ID
            </Typography>
          </TableCell>
          <TableCell>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.content}>
                    <Typography
                      variant="h6"
                    >
                      {ext_id}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow> }
        { ext_id_history && ext_id_history.length>0 && <TableRow>
          <TableCell className={classes.action_label}>
            <Typography variant="subtitle2">
              New BestBuy Order ID
            </Typography>
          </TableCell>
          <TableCell>
            <TextField
              className={classes.field}
              fullWidth
              label="BestBuy Order ID ( from 'Order Previous BestBuy IDs' list only )"
              margin="dense"
              name="bbOrderId"
              onChange={handleBbOrderIdChange}
              value={bbOrderIdNew}
              variant="outlined"
            />
          </TableCell>
        </TableRow> }
        <TableCell >
          <Button
            className={classes.updateButton}
            color="primary"
            size="small"
            onClick={handleClickOpenUpdateConfirmation}
            variant="contained"
          >
            {isProgressIn && <CircularProgress
              className={classes.spinnerIcon}
              size={20}
            />}
            &nbsp;
            Save attributes
            &nbsp;
          </Button>
        </TableCell>
        <TableCell />

      </TableBody>
    </Table>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseUpdateConfirmation}
        open={openUpdateConfirmation}
      >
        <DialogTitle id="alert-dialog-title">Update Order #{order_id}?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone. Be careful!
          </DialogContentText>
          <TextField
            fullWidth
            label="Note"
            margin="dense"
            name="comment"
            onChange={handleNoteChange}
            type="text"
            multiline={true}
            rows={5}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCloseUpdateConfirmation}
          >
            Cancel
          </Button>
          <Button
            disabled={!note || note === ''}
            autoFocus
            color="primary"
            onClick={handleButtonUpdate}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  );
};

UpdateAttributes.propTypes = {
  // className: PropTypes.string,
  application_id: PropTypes.string,
  checkout_token: PropTypes.string,
  ext_id: PropTypes.string,
  ext_id_history: PropTypes.array,
  lease_id: PropTypes.string,
  lease_number: PropTypes.string,
  lease_provider: PropTypes.string,
  order_id: PropTypes.number.isRequired,
  store_type: PropTypes.string,
  transaction_id: PropTypes.string,
};

export default UpdateAttributes;
