import {
  CREATE_ORDER_ADD_QTY_PRODUCTS,
  CREATE_ORDER_DELETE_PRODUCTS,
  CREATE_ORDER_SET_BILLING_ADDRESS,
  CREATE_ORDER_SET_BILLING_VALUE,
  CREATE_ORDER_SET_COMMENTS,
  CREATE_ORDER_SET_DELIVERY_OPTIONS,
  CREATE_ORDER_SET_LEASE_PROVIDER,
  CREATE_ORDER_SET_APPLICATION_ID,
  CREATE_ORDER_SET_APPROVAL_AMOUNT,
  CREATE_ORDER_SET_PRODUCTS,
  CREATE_ORDER_SET_SELECTED_VALUE,
  CREATE_ORDER_SET_SHIPPING,
  CREATE_ORDER_SET_STAFF_NOTES,
  CREATE_ORDER_SET_STEP,
  CREATE_ORDER_SET_VALUE,
  CREATE_ORDER_SET_STORE_TYPE,
  SET_INITIAL
} from '../actions';


export const defaultState = {
  step: 0,
  search: '',
  current_customer: null,
  addresses: {
    billing_address: {
      first_name: '',
      last_name: '',
      street_1: '',
      street_2: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      company: '',
      country: 'United States',
      country_iso2: 'US'
    },
    shipping_address: {
      firstname: '',
      lastname: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalcode: '',
      phonenumber: '',
      country: 'United States',
      country_iso2: 'US'
    },
    delivery_options: null
  },
  products: [],
  comments: '',
  staff_notes: '',
  lease_provider: '',
  application_id: '',
  approval_amount: 0,
  application_status: '',
  source: 'dorado_digital',
  order_source: 'manual',
  store_type: 'furniture',
  subtotal: 0,
  shipping: 0,
  grand_total: 0
};

const isBestBuy = (store) => store === 'electronics';

export const createOrder = (state = defaultState, action) => {
  switch (action?.type) {

    case SET_INITIAL: {
      return {
        ...defaultState
      };
    }

    case CREATE_ORDER_SET_STEP: {
      let shipping = state.shipping;
      let grand_total = state.grand_total;
      if (action.payload === 3) {
        if (isBestBuy(state.store_type)) {
          const prices = Object.values(state.addresses.delivery_options).map((value) => value?.selected_shipping_option?.price).filter(price => typeof price === 'string');
          shipping = prices.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
          grand_total += shipping;
        } else {
          shipping = 0;
          // not add shipping, because shippingFee was added to calculatePriceWithShippingFee product
          // const prices = state.products.map((value) => value?.shippingFee)
          // shipping = prices.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0)
          // grand_total += shipping
        }
      }
      return {
        ...state,
        step: action.payload,
        shipping,
        grand_total
      };
    }

    case CREATE_ORDER_SET_VALUE: {
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };
    }

    case CREATE_ORDER_SET_BILLING_VALUE: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          billing_address: {
            ...state.addresses.billing_address,
            [action.payload.key]: action.payload.value
          }
        }
      };
    }

    case CREATE_ORDER_SET_SELECTED_VALUE: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          billing_address: {
            ...state.addresses.billing_address,
            state: action.payload
          }
        }
      };
    }

    case CREATE_ORDER_SET_BILLING_ADDRESS: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          billing_address: {
            ...state.addresses.billing_address,
            ...action.payload
          }
        }
      };
    }

    case CREATE_ORDER_SET_SHIPPING: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          shipping_address: {
            ...state.addresses.shipping_address,
            ...action.payload,
            firstname: action.payload.first_name,
            lastname: action.payload.last_name,
            address1: action.payload.street_1,
            address2: action.payload.street_2,
            city: action.payload.city,
            state: action.payload.state,
            postalcode: action.payload.zip,
            phonenumber: action.payload.phone
          }
        }
      };
    }

    case CREATE_ORDER_SET_STORE_TYPE: {
      return {
        ...state,
        store_type: action.payload
      };
    }

    case CREATE_ORDER_SET_PRODUCTS: {
      const { sku, calculatedPrice, salePrice, calculatedPriceWithShippingFee, costPrice } = action.payload;
      const existingProductIndex = state.products.findIndex(product => product.sku === sku);

      // console.log({payloads: action.payload, existingProductIndex, state: state.subtotal, store_type: state.store_type});

      let subtotal = state.subtotal + (isBestBuy(state.store_type) ? (calculatedPrice || salePrice) : calculatedPriceWithShippingFee);
      subtotal = parseFloat(subtotal?.toFixed(2));
      let grand_total = state.grand_total + (isBestBuy(state.store_type) ? (calculatedPrice || salePrice) : calculatedPriceWithShippingFee);
      grand_total = parseFloat(grand_total?.toFixed(2));

      // console.log("[2]");
      console.log({ subtotal, grand_total });
      if (existingProductIndex !== -1) {
        // console.log("[3]");
        const updatedProducts = [...state.products];
        const quantity = updatedProducts[existingProductIndex].quantity + 1;
        const base_cost_price = isBestBuy(state.store_type) ? salePrice : costPrice;
        const total_inc_tax = (isBestBuy(state.store_type) ? (calculatedPrice || salePrice) : calculatedPriceWithShippingFee) * quantity;

        updatedProducts[existingProductIndex].base_cost_price = parseFloat(base_cost_price?.toFixed(2));
        updatedProducts[existingProductIndex].total_inc_tax = parseFloat(total_inc_tax?.toFixed(2));
        updatedProducts[existingProductIndex].quantity = quantity;

        return {
          ...state,
          products: updatedProducts,
          subtotal,
          grand_total
        };
      } else {
        // console.log("[4]");
        return {
          ...state,
          products: [
            ...state.products,
            {
              ...action.payload,
              base_cost_price: isBestBuy(state.store_type) ? salePrice : costPrice,
              total_inc_tax: isBestBuy(state.store_type) ? (calculatedPrice || salePrice) : calculatedPriceWithShippingFee,
              quantity: 1
            }
          ],
          subtotal,
          grand_total
        };
      }
    }

    case CREATE_ORDER_DELETE_PRODUCTS: {
      const existingProductIndex = state.products.findIndex(product => product.sku === action.payload);
      // let updatedTotal = state.subtotal;
      // let updated_grand_total = state.grand_total;
      const updatedDeliveryOptions = { ...state.addresses.delivery_options };
      delete updatedDeliveryOptions[action.payload];

      if (existingProductIndex !== -1) {
        const updatedProducts = [...state.products];
        const removedProduct = updatedProducts[existingProductIndex];

        updatedProducts.splice(existingProductIndex, 1);

        /// updatedTotal = updatedTotal - (isBestBuy(state.store_type) ? updatedProducts[existingProductIndex].salePrice : updatedProducts[existingProductIndex].costPrice);
        // updated_grand_total = updated_grand_total - (isBestBuy(state.store_type) ? updatedProducts[existingProductIndex].salePrice : updatedProducts[existingProductIndex].calculatedPriceWithShippingFee);
        let updatedSubtotal = parseFloat((state.subtotal - removedProduct.total_inc_tax).toFixed(2));
        let updatedGrandTotal = parseFloat((state.grand_total - removedProduct.total_inc_tax)?.toFixed(2));

        if (updatedProducts.length === 0) {
          updatedSubtotal = 0;
          updatedGrandTotal = 0;
        }

        return {
          ...state,
          products: updatedProducts,
          subtotal: updatedSubtotal,
          grand_total: updatedGrandTotal,
          addresses: {
            ...state.addresses,
            delivery_options: updatedDeliveryOptions,
          }
        };
      } else {
        return {
          ...state,
          addresses: {
            ...state.addresses,
            delivery_options: updatedDeliveryOptions,
          }
        };
      }
    }

    case CREATE_ORDER_ADD_QTY_PRODUCTS: {
      const { sku, quantity } = action.payload;
      const existingProductIndex = state.products.findIndex(product => product.sku === sku);

      if (existingProductIndex !== -1) {
        const updatedProducts = [...state.products];
        const updatedProduct = { ...updatedProducts[existingProductIndex] };

        const unitPrice = parseFloat((updatedProduct.total_inc_tax / updatedProduct.quantity).toFixed(2));
        updatedProduct.quantity = quantity;

        const updatedSubtotal = parseFloat((state.subtotal - updatedProduct.total_inc_tax + unitPrice * quantity).toFixed(2));
        const updatedGrandTotal = parseFloat((state.grand_total - updatedProduct.total_inc_tax + unitPrice * quantity).toFixed(2));

        updatedProduct.total_inc_tax = parseFloat((unitPrice * updatedProduct.quantity).toFixed(2));
        updatedProducts[existingProductIndex] = updatedProduct;

        if (updatedProduct.quantity === 0) {
          updatedProducts.splice(existingProductIndex, 1);
        }
        if (updatedProducts.length === 0) {
          return {
            ...state,
            products: [],
            subtotal: 0,
            grand_total: 0
          };
        }

        return {
          ...state,
          products: updatedProducts,
          subtotal: updatedSubtotal,
          grand_total: updatedGrandTotal
        };
      } else {
        return state;
      }
    }

    case CREATE_ORDER_SET_DELIVERY_OPTIONS: {
      const fulfillment = action.payload.shipping_option?.deliveryTo === 'store' ? 'store' : 'address';
      const products = state.products;
      const current_product = state.products.findIndex(product => product.sku === action.payload.sku);
      if (current_product !== -1) {
        products[current_product].fulfillment = fulfillment;
      }
      return {
        ...state,
        addresses: {
          ...state.addresses,
          delivery_options: {
            ...state.addresses.delivery_options,
            [action.payload.sku]: {
              ...action.payload.shipping_option,
              ...(fulfillment === 'address' && { delivery_address: state.addresses.shipping_address })
            }
          }
        },
        products
      };
    }

    case CREATE_ORDER_SET_COMMENTS: {
      return {
        ...state,
        comments: action.payload
      };
    }

    case CREATE_ORDER_SET_STAFF_NOTES: {
      return {
        ...state,
        staff_notes: action.payload
      };
    }

    case CREATE_ORDER_SET_LEASE_PROVIDER: {
      const selected_provider = state.current_customer?.[action.payload];
      return {
        ...state,
        lease_provider: action.payload,
        application_id: selected_provider?.application_id || '',
        approval_amount: selected_provider?.approval_amount || 0,
        application_status: selected_provider?.application_status || 'approved',
      };
    }

    case CREATE_ORDER_SET_APPLICATION_ID: {
      return {
        ...state,
        application_id: action.payload
      };
    }

    case CREATE_ORDER_SET_APPROVAL_AMOUNT: {
      return {
        ...state,
        approval_amount: action.payload
      };
    }

    default:
      return state;
  }
};
