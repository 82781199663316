import React, {useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
  Box,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import { LEASE_PROVIDERS_TYPE } from '../../../../common';
import { OrderItem } from '../OrderItem';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  address: {
    textTransform: 'capitalize'
  },
  columnContainer: {
    '& > .MuiGrid-item': {
      marginBottom: theme.spacing(2)
    }
  },
  columnItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  firstColumnItem: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  secondColumnItem: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  textField: {
    margin: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  dashedDivider: {
    borderBottom: `1px dashed ${theme.palette.divider}`,
    width: '100%',
    margin: `${theme.spacing(2)} 0`
  },
  leaseField: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  }
}));

export const Payment = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    products,
    addresses: {
      billing_address: { first_name, last_name, street_1, street_2, company, city, state, zip, phone, country },
      shipping_address,
      delivery_options
    },
    lease_provider,
    application_id,
    approval_amount,
    subtotal,
    shipping,
    grand_total,
    store_type
  } = useSelector(({ createOrder }) => createOrder);
  const [editLease, setEditLease] = useState(false);

  console.log('redux state value on start!!!!!!!!!!!!!!!!!', lease_provider);

  useEffect(() => {
    dispatch({
      type: 'CREATE_ORDER_SET_SHIPPING',
      payload: { first_name, last_name, street_1, street_2, company, city, state, zip, phone, country }
    });
  }, [dispatch, first_name, last_name, street_1, street_2, company, city, state, zip, phone, country]);

  useEffect(() => {
    if (!lease_provider) {
      console.log('Set initial lease provider');
      const provider = LEASE_PROVIDERS_TYPE.filter((provider) => provider.store_type === store_type)[0];
      dispatch({
        type: 'CREATE_ORDER_SET_LEASE_PROVIDER',
        payload: provider.value
      });
    }
  }, [dispatch, store_type, lease_provider]);

  const handleComments = useCallback((event) => {
    dispatch({
      type: 'CREATE_ORDER_SET_COMMENTS',
      payload: event.target.value
    });
  }, [dispatch]);

  const handleNotes = useCallback((event) => {
    dispatch({
      type: 'CREATE_ORDER_SET_STAFF_NOTES',
      payload: event.target.value
    });
  }, [dispatch]);

  const handleLeaseProvider = useCallback((event) => {
    dispatch({
      type: 'CREATE_ORDER_SET_LEASE_PROVIDER',
      payload: event.target.value
    });
  }, [dispatch]);

  const handleApplicationId = useCallback((event) => {
    dispatch({
      type: 'CREATE_ORDER_SET_APPLICATION_ID',
      payload: event.target.value
    });
  }, [dispatch]);

  const handleApprovalAmount = useCallback((event) => {
    if (/^\d*$/.test(event.target.value)) {
      dispatch({
        type: 'CREATE_ORDER_SET_APPROVAL_AMOUNT',
        payload: +event.target.value
      });
    }
  }, [dispatch]);

  const handleEditLease = useCallback((event) => {
    event.preventDefault();
    setEditLease(true)
  }, []);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        className={classes.columnContainer}
        item
        md={8}
        xs={12}
      >
        <Grid
          className={classes.columnItem}
          container
          spacing={2}
        >
          <Grid
            className={classes.firstColumnItem}
            item
            xs={12}
          >
            <Typography variant="h3">Customer billing details</Typography>
            <Card
              className={classes.card}
            >
              <CardContent>
                <Grid
                  container
                  item
                  xs={8}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <Typography variant="h6">Name</Typography>
                    <Typography variant="h6">Company</Typography>
                    <Typography variant="h6">Phone</Typography>
                    <Typography variant="h6">Address</Typography>
                    <Typography variant="h6">Suburb/City</Typography>
                    <Typography variant="h6">State/Province</Typography>
                    <Typography variant="h6">Country</Typography>
                    <Typography variant="h6">ZIP/Postcode</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Typography variant="body2">{`${first_name} ${last_name}` || '-'}</Typography>
                    <Typography variant="body2">{company || '-'}</Typography>
                    <Typography variant="body2">{phone || '-'}</Typography>
                    <Typography variant="body2">{`${street_1} ${street_2}` || '-'}</Typography>
                    <Typography variant="body2">{city || '-'}</Typography>
                    <Typography variant="body2">{state || '-'}</Typography>
                    <Typography variant="body2">{country || '-'}</Typography>
                    <Typography variant="body2">{zip || '-'}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            className={classes.firstColumnItem}
            item
            xs={12}
          >
            <Typography variant="h3">Fulfillment details</Typography>
            <Card
              className={classes.card}
            >
              <CardContent>
                <Grid
                  item
                  name="shipping_address"
                  xs
                >
                  <div className={classes.address}>
                    {shipping_address?.first_name || ''} {shipping_address?.last_name || ''}
                  </div>
                  <div className={classes.address}>
                    {shipping_address?.street_1 || ''}<
                  /div>
                  {shipping_address?.street_2 && 
                    <div className={classes.address}>{shipping_address?.street_2 || ''}</div>
                  }
                  <div className={classes.address}>
                    {shipping_address?.city || ''} {shipping_address?.state || ''} {shipping_address?.zip || ''}
                  </div>
                  <div className={classes.address}>{shipping_address?.country || ''}</div>
                  <div>{shipping_address?.phone || ''}</div>
                </Grid>
              </CardContent>

              {products.map((item, index) => (
                <OrderItem
                  delivery_options={delivery_options[item?.sku]}
                  index={index + 1}
                  item={item}
                  key={index}
                />
              ))}
            </Card>
          </Grid>
          <Grid
            className={classes.firstColumnItem}
            item
            xs={12}
          >
            <Typography variant="h3">Comments and notes</Typography>
            <Card
              className={classes.card}
            >
              <CardContent>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Comments"
                  margin="dense"
                  minRows={4}
                  multiline
                  name="comments"
                  onChange={handleComments}
                  type="text"
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Staff notes"
                  margin="dense"
                  minRows={4}
                  multiline
                  name="staff_notes"
                  onChange={handleNotes}
                  type="text"
                  variant="outlined"
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={classes.columnContainer}
        item
        md={4}
        xs={12}
      >
        <Grid
          className={classes.columnItem}
          container
          spacing={2}
        >
          <Grid
            className={classes.secondColumnItem}
            item
            xs={12}
          >
            <Typography variant="h3">Payment</Typography>
            <Card
              className={classes.card}
            >
              <CardContent>
                <Box
                  sx={{ display: 'flex' }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      label="Lease Provider"
                      name="leaseProvider"
                      onChange={handleLeaseProvider}
                      select
                      // eslint-disable-next-line react/jsx-sort-props
                      SelectProps={{ native: true }}
                      value={lease_provider}
                      variant="outlined"
                    >
                      {LEASE_PROVIDERS_TYPE.map(option => {
                        if (option.store_type === store_type) {
                          return (
                            <option
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </option>
                          );
                        }
                        return null;
                      })}
                    </TextField>
                  </Box>
                  <Box></Box>
                </Box>
                <Box
                  sx={{ display: 'flex' }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <TextField
                      className={classes.leaseField}
                      fullWidth
                      name={'Application ID'}
                      onChange={handleApplicationId}
                      placeholder={'Application ID'}
                      size="small"
                      value={application_id}
                      variant="outlined"
                      disabled={!editLease}
                    />
                    <TextField
                      className={classes.leaseField}
                      fullWidth
                      name={'Approval Amount'}
                      onChange={handleApprovalAmount}
                      placeholder={'Approval Amount'}
                      size="small"
                      value={approval_amount}
                      variant="outlined"
                      disabled={!editLease}
                    />
                  </Box>
                  <Box sx={{ p: 1 }}>
                    <IconButton size={'small'} onClick={handleEditLease}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                </Box>


              </CardContent>
            </Card>
          </Grid>
          <Grid
            className={classes.secondColumnItem}
            item
            xs={12}
          >
            <Typography variant="h3">Summary</Typography>
            <Card
              className={classes.card}
            >
              <CardContent>
                <Grid
                  container
                  xs={12}
                >
                  <Grid
                    item
                    xs={9}
                  >
                    <Typography variant="h6">Subtotal:</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <Typography variant="body1">{`$ ${parseFloat(subtotal).toFixed(2)}`}</Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  container
                  xs={12}
                >
                  <Grid
                    item
                    xs={9}
                  >
                    <Typography variant="h6">Shipping:</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <Typography variant="body1">{`$ ${parseFloat(shipping).toFixed(2)}`}</Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  container
                  xs={12}
                >
                  <Grid
                    item
                    xs={9}
                  >
                    <Typography variant="h6">Grand total:</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <Typography variant="body1">{`$ ${parseFloat(grand_total).toFixed(2)}`}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
